<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <form class="filter position-relative mb-4 fs-6 fw-bolder text-gray-600" ref="searchRef" @submit.prevent="onFilter">
                        <div class="filter-item">
                            <span>DESAIN ID</span>
                            <el-input v-model="filter.desain_id" placeholder="Search ID" clearable />
                        </div>
                        <div class="filter-item">
                            <span>JENIS</span>
                            <el-select v-model="filter.jenis_desain" placeholder="Jenis" clearable>
                                <el-option v-for="(o, i) in listJenisDesain" :key="i" :value="o.jenis" :label="o.jenis"/>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>FOLDER</span>
                            <el-select v-model="filter.folder_id" placeholder="Pilih Folder" clearable>
                                <el-option v-for="(o, i) in listFolderDesain" :key="i" :value="o.id" :label="o.name">
                                    <span style="float: left">{{ o.name }}</span>
                                    <span style="float: right">({{ o.total }})</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter('bankdesain'), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('bankdesain'), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </form>
                </div>
                <div class="card-toolbar" style="margin-left:auto"></div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div class="">
                <div class="item" ref="itemRef" v-if="!loadingButton && listBankDesain.length > 0">
                    <div v-for="(item, index) in listBankDesain" :key="index" class="item-list" @click="getDesign(item)">
                        <div class="item-image mb-2">
                            <img :src="img_url+item.gambar"/>
                            <span class="stack-top left">{{ item.tipe_desain ? item.tipe_desain : 'TIDAK ADA' }}</span>
                        </div>
                        <div class="item-name mb-2">
                            <span>{{ item.name+' ('+item.id+')' }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="!loadingButton && listBankDesain.length == 0" class="text-center">
                    <img class="data-not-found" src="@/assets/images/absurd/patrick-not-found.png"/>
                </div>
                <div class="text-center">
                    <el-pagination 
                        background layout="prev, pager, next" 
                        :pager-count="4" 
                        :page-count="pagination.total_page"
                        @next-click="handlePageChange" 
                        @prev-click="handlePageChange" 
                        @current-change="handlePageChange" 
                        @update:current-page="currentPage"
                    />
                </div>
            </div>
        </div>

        <el-dialog v-model="modalVisible.desain" :title="'Add Raw Desain'" width="600px" :close-on-click-modal="true">
            <Form
                class="form w-100"
                :validation-schema="tempData"
                @submit="onSubmit()"
            >
                <input type="hidden" name="id" v-model="tempData.id"/>
                <div class="scroll-y me-n7 pe-7">
                    <div class="fv-row mb-7">
                        <inner-image-zoom :src="img_url+tempData.gambar"/>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Desain ID</label>
                        <Field v-model="tempData.sku" type="text" name="sku" v-slot="{ field }">
                            <input v-bind="field" class="form-control" disabled/>
                        </Field>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Artikel</label>
                        <Field type="text" class="form-control" placeholder="" v-model="tempData.artikel" name="artikel" v-slot="{ field }">
                            <input v-bind="field" class="form-control" disabled/>
                        </Field>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Nama</label>
                        <Field type="text" class="form-control" placeholder="" v-model="tempData.name" name="name" v-slot="{ field }">
                            <input v-bind="field" class="form-control" disabled/>
                        </Field>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Tipe Desain</label>
                        <span class="form-control">{{ listDesainKategori.length ? listDesainKategori[0].tipe_desain : 'Tidak ada' }}</span>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Desain Kategori</label>
                        <span class="form-control">{{ listDesainKategori.length ? listDesainKategori.map(o => o.name).join([separator = ', ']) : 'Tidak ada' }}</span>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Ukuran Print</label>
                        <Field as="select" class="form-select" v-model="tempData.print_size_id" name="print_size_id" @change="listPrintSize.find(o => { if(o.id == tempData.print_size_id) { tempData.price = o.price } })">
                            <option v-for="ps in listPrintSize" :key="ps" :value="ps.id" @click="tempData.price = ps.price">{{ ps.name }}({{ ps.detail }})</option>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="print_size_id" />
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="fs-6 fw-bold mb-2">HPP Print</label>
                        <input :value="tempData.price ? formatIDR(tempData.price, { prefix: 'Rp'}) : 0" class="form-control" disabled/>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modalVisible.desain = false">Cancel</button>
                    <button
                        type="submit"
                        ref="submitButton"  
                        class="btn btn-primary"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </Form>
        </el-dialog>

        <el-dialog v-model="viewImageVisible" :title="viewImageTitle" width="50%" lock-scroll>
            <inner-image-zoom :src="viewImageSrc" />
        </el-dialog>

    </div>
</template>

<script>
import { App } from "vue";
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, provide } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { ElLoading, Elpagination } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: { 
        Field,
        Form,
        ErrorMessage, 
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const img_url = ref(process.env.VUE_APP_IMAGE_DESAINS)

        const store = useStore();
        const route = useRoute()
        const router = useRouter()

        const loadingButton = ref(false);

        const search = ref(null)
        const filterActive = ref(false)
        const filter = reactive({
            desain_id: null,
            folder_id: null,
            jenis_desain: null,
        })
        const itemRef = ref(null)

        const change_artikel = ref(true)
        const change_sku = ref(true)
        
        const submitButton = ref(null);
        
        const tempData = reactive(Yup.object().shape({
            id: '',
            gambar: '',
            sku: '',
            artikel: '',
            name: '',
            desain_kategori: '',
            print_size_id: Yup.string().required().label("Ukuran Print"),
            print_size_name: '',
            print_size_detail: '',
            price: '',
        }));

        const listPrintSize = reactive([])
        const listDesainKategori = reactive([])

        const modalVisible = ref({
            desain: false,
            bank_desain: false,
        })
        
        const modalTitle = ref('')
        
        const loadingFull = ({message: message = 'Tunggu', background: background = 'rgba(255,255,255,0.7)', lock: lock = true}) => {
            const loading = ElLoading.service({
                lock: lock,
                text: message,
                background: background,
            })

            return loading
        }

        const getPrintSize = () => {
            ApiService.setHeader();
            ApiService.get("print_size")
            .then(({ data }) => {
                Object.assign(listPrintSize, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list print size');
            });
        }

        const getDesign = async(row) => {
            // Assign Input Desain
            tempData.id = row.id
            tempData.sku = row.id
            tempData.artikel = 0
            tempData.name = row.name
            tempData.price = ''
            tempData.gambar = row.gambar
            tempData.print_size_name = ''
            tempData.print_size_detail = ''
            tempData.print_size_id = ''

            listPrintSize.splice(0)
            listDesainKategori.splice(0)
            
            const loading = ElLoading.service({
                lock: true,
                text: 'Tunggu',
                background: 'rgba(255,255,255,0.7)'
            })

            try {
                await getPrintSize()
                await desainKategori(row.id)

                if(listDesainKategori.length == 0) {
                    throw new Error('Desain kategori tidak ada')
                }

                modalVisible.value.desain = true

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });

            } finally {
                loading.close()
            }

        }
        
        const listBankDesain = reactive([])

        // PAGINATION
        const currentPage = ref(1)

        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (val) => {
            itemRef.value && (itemRef.value.scrollTop = 0) // Reset scroll position
            await getBankdesain(val)
            currentPage.value = val
        }

        const desainKategori = async (id) => {
            listDesainKategori.splice(0)
            await ApiService.setHeader();
            await ApiService.get("desain_kategori/"+id)
            .then(({ data }) => {
                //console.log(data)
                Object.assign(listDesainKategori, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list desain_kategori');
            });
        }
        
        const listJenisDesain = reactive([])
        const listFolderDesain = reactive([])

        const jenisDesain = async() => {
            await ApiService.setHeader();
            await ApiService.get("jenis_desain")
            .then(({ data }) => {
                //console.log(data)
                Object.assign(listJenisDesain, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list jenis desain');
            });
        }

        const folderDesain = async() => {
            listFolderDesain.splice(0)

            await ApiService.setHeader();
            await ApiService.get('not_raw_folder')
            .then(({ data }) => {
                Object.assign(listFolderDesain, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list folder desain');
            });
        }

        const onFilter = async (type) => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                return
            }

            await getBankdesain()

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          Object.keys(filter).map(key => filter[key] = null);
          filterActive.value = false

          const loading = ElLoading.service({
              lock: true,
              text: 'Tunggu',
              background: 'rgba(255,255,255,0.7)'
          })

          await getBankdesain()

          loading.close()
        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = 'DESAIN ID - '+data.sku
            viewImageVisible.value = true
            viewImageSrc.value = img_url.value +data.gambar
        }
    
        const onSubmit = async () => {
            const formData = new FormData();
            let id = ''

            if(submitButton.value) {
                submitButton.value.disabled = true;
                submitButton.value.setAttribute("data-kt-indicator", "on");
            }

            id = tempData.id
            formData.append('id', parseInt(tempData.id))
            formData.append('sku', tempData.sku)
            formData.append('artikel', tempData.artikel)
            formData.append('name', tempData.name)
            formData.append('raw_category_id', 35) // Default Desain Kategori 35
            formData.append('size_category_id', tempData.size_category_id)
            formData.append('desain_kategori', tempData.desain_kategori)
            formData.append('print_size_id', tempData.print_size_id)
            formData.append('price', tempData.price)
            formData.append('vendor_id', 1) // Default Vendor 1
            formData.append('image', tempData.gambar)
            formData.append('type', 'desain')

            try {
                await ApiService.setHeader();
                await ApiService.post("product_raw/create", formData)
                .then(({ data }) => {
                    if(data.error) {
                        Swal.fire({
                            title: data.error.messages,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000
                        });

                        return
                    }
                    
                    modalVisible.value.desain = false

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        buttonsStyling: false,
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'Goto raw desain',
                        cancelButtonText: 'Stay here',
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                            cancelButton: "btn fw-bold btn-light",
                        },
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch(Actions.CREATE_RAW_DESAIN, { type: 'desain', data: tempData.sku });
                            router.push({name:'product_raw'})
                        }
                    })
                })
                .catch(({ error }) => {
                    if(error) throw Error(error)
                });
            
            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
            
            if(submitButton.value) {
                submitButton.value.removeAttribute("data-kt-indicator");
                submitButton.value.disabled = false;
            }
        }

        const removeObjectArray = (array, predicate) => {
            let end = 0;

            for (let i = 0; i < array.length; i++) {
                const obj = array[i];

                if (predicate(obj)) {
                    array[end++] = obj;
                }
            }

            array.length = end;
        };

        const getBankdesain = async(page = 1) => {
            loadingButton.value = true
            const loading = ElLoading.service({
                lock: true,
                text: 'Tunggu',
                background: 'rgba(255,255,255,0.7)'
            })

            listBankDesain.splice(0)

            await ApiService.setHeader();
            await ApiService.post("bankdesain?page="+page, { filter: filter })
            .then(({ data }) => {
                currentPage.value = page
                pagination.value = data.data.pagination
                Object.assign(listBankDesain, data.data.data)
            })
            .catch(({ response }) => {
                console.log('Error getting list bankdesain');
            });

            loadingButton.value = false
            loading.close()
        }

        onMounted(async() => {
            setCurrentPageBreadcrumbs("Create Raw Desain", ["Bank Desain", "Create Raw Desain"]);

            getBankdesain()
            jenisDesain()
            folderDesain()
        })

        return {
            itemRef, filter, filterActive,
            img_url, viewImage, viewImageSrc, viewImageVisible, viewImageTitle,
            loadingButton, tempData,
            modalVisible, modalTitle, listPrintSize, listDesainKategori, desainKategori, listFolderDesain, listJenisDesain,
            listBankDesain, getDesign,
            onFilter, resetFilter,
            pagination, handlePageChange, currentPage, 
            onSubmit, submitButton,
            formatIDR,
        }
    }
})
</script>